<template>
  <div class="leaderboard-wrapper">
    <div class="content-wrapper">
      <div class="my-rank-wrapper">
        <div class="my-rank">
          <h4 class="no-rank" v-if="myRank === 0">未上榜</h4>
          <svg aria-hidden="true" v-else-if="myRank === 1">
            <use xlink:href="#iconicon-test"></use>
          </svg>
          <svg aria-hidden="true" v-else-if="myRank === 2">
            <use xlink:href="#iconicon-test1"></use>
          </svg>
          <svg aria-hidden="true" v-else-if="myRank === 3">
            <use xlink:href="#iconicon-test2"></use>
          </svg>
          <h4 class="rank" v-else>{{myRank}}</h4>
          <p>我的排名</p>
        </div>
        <div class="my-info">
          <div class="avatar">
            <img :src="userInfo.headUrl" alt="">
          </div>
          <p class="van-ellipsis">{{userInfo.userName}}</p>
        </div>
        <div class="my-point"><span>{{myPoint}}</span>积分</div>
      </div>
      <div class="rank-list-wrapper">
        <div class="rank-item-wrapper" v-for="(item, index) in data" :key="index">
          <div class="rank">
            <svg aria-hidden="true" v-if="item.rank === 1">
              <use xlink:href="#iconicon-test"></use>
            </svg>
            <svg aria-hidden="true" v-else-if="item.rank === 2">
              <use xlink:href="#iconicon-test1"></use>
            </svg>
            <svg aria-hidden="true" v-else-if="item.rank === 3">
              <use xlink:href="#iconicon-test2"></use>
            </svg>
            <h4 class="rank" v-else>{{item.rank}}</h4>
          </div>
          <div class="user-info-wrapper border-bottom-1px">
            <div class="user-info">
              <img v-if="item.headUrl" v-lazy="item.headUrl" :src="item.headUrl" alt="">
              <img v-else src="/avatar.png" alt="">
              <h4 class="van-ellipsis">{{item.userName}}</h4>
            </div>
            <div class="user-point"><span>{{item.creditAmount}}</span>积分</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index'
const { getCreditRanks } = API

export default {
  name: 'leaderBoard',
  data () {
    return {
      myRank: 0,
      myPoint: 961,
      data: []
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
    this.getCreditRanks()
  },
  methods: {
    // 获取svg图标
    getSvgIcon (rank) {
      switch (rank) {
        case 1: return '#iconicon-test'
        case 2: return '#iconicon-test1'
        case 3: return '#iconicon-test2'
      }
    },
    // 获取排名列表
    async getCreditRanks () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let res = await getCreditRanks()

      loading.clear()
      if (res.success) {
        this.data = res.data.rankVos
        this.myRank = res.data.rank
        this.myPoint = res.data.creditAmount
      } else {
        this.$toast.fail(res.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .leaderboard-wrapper{
    .content-wrapper{
      background: #00ABFC;
      padding: 57px 24px;
      .my-rank-wrapper{
        @include flexCenter(space-between);
        padding: 24px;
        box-sizing: border-box;
        width: 702px;
        height: 196px;
        background: #0482FF;
        border-radius: 20px 20px 0px 0px;
        position: relative;
        color: #fff;
        &::before,&::after{
          content: '';
          position: absolute;
          bottom: 0;
          border: 11px solid #0B5CB4;
          transform: translateY(100%);
          border-bottom-color: transparent;
        }
        &::before{
          left: 0;
          border-left-color: transparent;
        }

        &::after{
          right: 0;
          border-right-color: transparent;
        }

        .my-rank{
          text-align: center;
          margin-right: 40px;
          .no-rank{
            margin-bottom: 24px;
          }
          svg{
            width: 64px;
            height: 75px;
          }
          .rank{
            font-size: 50px;
            font-weight: bold;
          }
          p{
            margin-top: 8px;
            font-size: 24px;
          }
        }
        .my-info{
          flex: 1;
          display: flex;
          align-items: center;
          .avatar{
            @include circle(106px);
            border: 6px solid #fff;
            margin-right: 30px;
            >img{
              @include circle(106px);
            }
          }
          p{
            width: 180px;
            font-size: 28px;
            font-weight: 600;
            line-height: 1.5;
          }
        }
        .my-point{
          font-size: 24px;
          color: #FAC222;
          span{
            font-size: 44px;
            font-weight: bold;
            margin-right: 6px;
          }
        }
      }

      .rank-list-wrapper{
        width: 658px;
        background: #fff;
        border-radius: 0px 0px 10px 10px;
        margin: 0 auto;
        box-shadow: inset 0 22px 22px -22px #0482FF;

        .rank-item-wrapper{
          @include flexCenter(space-between);
          padding-left: 28px;
          box-sizing: border-box;
          .rank{
            color: #526779;
            font-weight: bold;
            font-size: 36px;
            text-align: center;
            width: 46px;
            svg{
              width: 46px;
              height: 54px;
            }
          }
        }
        .user-info-wrapper{
          @include flexCenter(space-between);
          margin-left: 35px;
          flex: 1;
          padding: 28px 0;
          padding-right: 28px;
          .user-info{
            display: flex;
            align-items: center;
            img{
              @include circle(90px);
              margin-right: 22px;
            }
            h4{
              width: 250px;
              font-size: 28px;
              color: #526779;
              font-weight: 600;
              line-height: 1.5;
            }
          }
          .user-point{
            color: #FC5E25;
            font-size: 24px;
            span{
              font-size: 40px;
              font-weight: bold;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
</style>
